<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Skills`) }}</h6>
        <div class="detail-field px-1" v-if="candidateDetails.skills && candidateDetails.skills.length > 0">
            <b-row>
                <b-col cols="4">

                </b-col>
                <b-col cols="4"
                       class=" text-center
                               text-uppercase
                               text-muted
                               font-small-2">
                    {{ i18nT(`Level`) }}
                </b-col>
            </b-row>

            <b-row
                v-for="(skill,index) in candidateDetails.skills"
                :key="index"
                class="pb-05 pt-05"
                :class="{'border-bottom': index < candidateDetails.skills.length - 1}"
            >
                <b-col cols="4">
                                            <span class="tag-container">
                                                {{ skill.Label }}
                                            </span>
                </b-col>

                <b-col cols="4" class="text-center pt-05">
                    <b-progress
                        :value="skill.LevelId"
                        variant="primary"
                        :max="3"></b-progress>
                </b-col>
            </b-row>
        </div>


        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No skills added`) }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BProgress,
} from 'bootstrap-vue';
export default {
    name: "CvLanguages",
    components: {
        BRow,
        BCol,
        BProgress,
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {

    },
}
</script>

<style scoped>

</style>
