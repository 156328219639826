<template>
    <b-row>
        <b-col cols="12">
            <hr class="invoice-spacing"/>
            <h6 class="section-label">{{ i18nT(`Relocation and remote work`) }}</h6>
        </b-col>

        <b-col md="4" sm="12">
            <div class="detail-field px-1 pt-05">
                <p>{{ i18nT(`Willing to relocate?`) }}: <strong>{{ candidateDetails.candidate.Relocate ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>
            </div>
        </b-col>

        <b-col md="4" sm="12">
            <div class="detail-field px-1 pt-05">
                <p>{{ i18nT(`Willing to travel?`) }}: <strong>{{ candidateDetails.candidate.Travel ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>
            </div>
        </b-col>

        <b-col md="4" sm="12">
            <div class="detail-field px-1 pt-05">
                <p>{{ i18nT(`Accepts remote work?`) }}: <strong>{{ candidateDetails.candidate.Remote ? i18nT(`Yes`) : i18nT(`No`) }}</strong></p>
            </div>
        </b-col>

    </b-row>
</template>

<script>
import { BRow, BCol} from 'bootstrap-vue';
export default {
    name: "CvRelocateRemote",
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        BRow,
        BCol
    },
}
</script>

<style scoped>

</style>
