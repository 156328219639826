<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Languages`) }}</h6>
        <div
            v-if="candidateDetails.languages && candidateDetails.languages.length > 0"
            class="detail-field px-1 pb-05">
            <b-row>
                <b-col cols="4">

                </b-col>
                <b-col cols="4"
                       class="
                               text-center
                               text-uppercase
                               text-muted
                               font-small-2
                                ">
                    {{ i18nT(`Spoken`) }}
                </b-col>
                <b-col cols="4"
                       class="
                                text-center
                                text-uppercase
                                text-muted
                                font-small-2
                                "
                >
                    {{ i18nT(`Written`) }}
                </b-col>
            </b-row>

            <b-row v-for="(language,index) in candidateDetails.languages"
                   :key="index"
                   class="pb-05 pt-05"
                   :class="{'border-bottom': index < candidateDetails.languages.length - 1}"
            >
                <b-col cols="4">
                    {{ language.Language }}
                </b-col>
                <b-col cols="4" class="text-center pt-05">
                    <b-progress
                        :value="language.SpeakId"
                        variant="primary"
                        :max="5"></b-progress>

                </b-col>
                <b-col cols="4" class="text-center pt-05">
                    <b-progress
                        :value="language.WriteId"
                        variant="primary"
                        :max="5"></b-progress>
                </b-col>
            </b-row>
        </div>

        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No languages added`) }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BProgress,
} from 'bootstrap-vue';
export default {
    name: "CvLanguages",
    components: {
        BRow,
        BCol,
        BProgress,
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {

    },
}
</script>

<style scoped>

</style>
