<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Summary`) }}</h6>

        <div class="detail-field" v-if="candidateDetails.candidate && candidateDetails.candidate.Presentation">
            {{ candidateDetails.candidate.Presentation }}
        </div>

        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No summary added`) }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CvSummary",
    components: {

    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {

    },
}
</script>

<style scoped>

</style>
