<template>

    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`References`) }}</h6>

        <div v-if="candidateDetails && candidateDetails.references && candidateDetails.references.length > 0" class="detail-field px-1">
            <b-row
                v-for="(ref,index) in candidateDetails.references"
                :key="index"
            >
                <b-col cols="12" v-if="ref.AttachedFile">
                    <b-link :href="ref.AttachedFile.DownloadUrl" target="_blank" >{{ ref.AttachedFile.Label }}</b-link>
                </b-col>
                <b-col cols="12" v-if="ref.Url">
                    <b-link :href="ref.Url" target="_blank" >{{ ref.Url }}</b-link>
                </b-col>
                <b-col cols="12">
                    <p class="text-muted font-small-3">{{ref.Label}}</p>
                </b-col>
            </b-row>
        </div>

        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No references added`) }}</h5>
            </div>
        </div>

    </div>

</template>

<script>
import {
    BRow,
    BCol,
    BLink
} from 'bootstrap-vue';
export default {
    name: "CvReferences",
    components: {
        BRow,
        BCol,
        BLink
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        hasSocials() {
            return this.candidateDetails.candidate.Linkedin ||
                this.candidateDetails.candidate.Youtube ||
                this.candidateDetails.candidate.Instagram ||
                this.candidateDetails.candidate.Dribble ||
                this.candidateDetails.candidate.Web ||
                this.candidateDetails.candidate.Twiter ||
                this.candidateDetails.candidate.Behance ||
                this.candidateDetails.candidate.Github;
        }
    },
}
</script>

<style scoped>

</style>
