<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Education`) }}</h6>
        <div class="detail-field" v-if="candidateDetails.education && candidateDetails.education.length > 0">
            <educations :data="candidateDetails.education"/>
        </div>
        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No education history added`) }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import {
    // BRow,
    // BCol,
    // BProgress,
} from 'bootstrap-vue';
import educations from "@/views/recruiting/Candidates/Educations.vue";
export default {
    name: "CvEducation",
    components: {
        // BRow,
        // BCol,
        // BProgress,
        educations
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {

    },
}
</script>

<style scoped>

</style>
