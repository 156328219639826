<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Resume`) }}</h6>
        <div class="detail-field px-1" v-if="CvObjLocal">
            <b-row
            >
                <b-col cols="12">
                    <b-link :href="CvObjLocal.DownloadUrl" target="_blank" >{{ CvObjLocal.Label }}</b-link>
                </b-col>
                <b-col cols="12">
                    <p class="text-muted font-small-3">{{ CvObjLocal.CreatedAt | date}}</p>
                </b-col>
            </b-row>
        </div>

        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No resume uploaded`) }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BLink
} from 'bootstrap-vue';
export default {
    name: "CvResume",
    components: {
        BRow,
        BCol,
        BLink
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        CvObjLocal() {
            if(this.candidateDetails && this.candidateDetails.current_application && this.candidateDetails.current_application.CvObj) {
                return this.candidateDetails.current_application.CvObj;
            } else if (this.candidateDetails && this.candidateDetails.freelancer && this.candidateDetails.freelancer.CvObj) {
                return this.candidateDetails.freelancer.CvObj;
            } else {
                return null;
            }
        }
    },
}
</script>

<style scoped>

</style>
