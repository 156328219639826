<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Certificates`) }}</h6>
        <div class="detail-field px-1" v-if="candidateDetails.certificates && candidateDetails.certificates.length > 0">
            <b-row
                v-for="(certificate,index) in candidateDetails.certificates"
                :key="index"
            >
                <b-col cols="12">
                    <b-link :href="certificate.Url" target="_blank" >{{ certificate.Label }}</b-link>
                </b-col>
                <b-col cols="12">
                    <p class="text-muted font-small-3">{{ certificate.Description}}</p>
                </b-col>
            </b-row>
        </div>

        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No certificates added`) }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BLink
} from 'bootstrap-vue';
export default {
    name: "CvCertificates",
    components: {
        BRow,
        BCol,
        BLink
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {

    },
}
</script>

<style scoped>

</style>
