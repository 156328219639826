<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Work Experience`) }}</h6>
        <div class="detail-field" v-if="candidateDetails.jobs && candidateDetails.jobs.length >0">
            <jobs :data="candidateDetails.jobs"/>
        </div>
        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No work experience added`) }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import {
    // BRow,
    // BCol,
    // BProgress,
} from 'bootstrap-vue';
import Jobs from "@/views/recruiting/Candidates/Jobs.vue";
export default {
    name: "CvWorkXp",
    components: {
        // BRow,
        // BCol,
        // BProgress,
        Jobs
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {

    },
}
</script>

<style scoped>

</style>
