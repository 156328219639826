<template>
    <div >
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Interests`) }}</h6>
        <div class="detail-field px-1" v-if="candidateDetails.interests && candidateDetails.interests.length > 0">
            <b-row
                v-for="(interest,index) in candidateDetails.interests"
                :key="index"
                class="pb-05 pt-05"
                :class="{'border-bottom': index < candidateDetails.interests.length - 1}"
            >
                <b-col cols="12">
                    <strong>{{ interest.Label}}</strong>
                </b-col>
                <b-col cols="12">
                    <p class="text-muted font-small-3">{{ interest.Description}}</p>
                </b-col>
            </b-row>
        </div>

        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No interests added`) }}</h5>
            </div>
        </div>


    </div>
</template>

<script>
import {
    BRow,
    BCol,
} from 'bootstrap-vue';
export default {
    name: "CvInterests",
    components: {
        BRow,
        BCol,

    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        hasSocials() {
            return this.candidateDetails.candidate.Linkedin ||
                this.candidateDetails.candidate.Youtube ||
                this.candidateDetails.candidate.Instagram ||
                this.candidateDetails.candidate.Dribble ||
                this.candidateDetails.candidate.Web ||
                this.candidateDetails.candidate.Twiter ||
                this.candidateDetails.candidate.Behance ||
                this.candidateDetails.candidate.Github;
        }
    },
}
</script>

<style scoped>

</style>
