<template>
    <div>
        <hr class="invoice-spacing"/>
        <h6 class="section-label">{{ i18nT(`Cover Letter`) }}</h6>
        <div class="detail-field px-1" v-if="candidateDetails.current_application && candidateDetails.current_application.CoverLetterObj">
            <b-row
            >
                <b-col cols="12">
                    <b-link :href="candidateDetails.current_application.CoverLetterObj.DownloadUrl" target="_blank" >{{ candidateDetails.current_application.CoverLetterObj.Label }}</b-link>
                </b-col>
                <b-col cols="12">
                    <p class="text-muted font-small-3">{{ candidateDetails.current_application.CvObj.CreatedAt | date}}</p>
                </b-col>
            </b-row>
        </div>

        <div class="detail-field px-1 pt-1" v-else>
            <div class="d-flex align-items-center align-content-center">
                <h5 class="text-muted">{{ i18nT(`No cover letter uploaded`) }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BLink
} from 'bootstrap-vue';
export default {
    name: "CvCoverLetter",
    components: {
        BRow,
        BCol,
        BLink
    },
    props: {
        candidateDetails: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {

    },
}
</script>

<style scoped>

</style>
